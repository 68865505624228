<template>
    <div>
        <div class="h-screen overflow-auto bg-gray-100 dark:bg-gray-800">
            <HeaderFrontend />
            <ImpersonateBanner />
            <BaseSlideOver
                :header-color="'primary'"
                :title="(slideOverStore?.title) ? $t(slideOverStore.title) : ''"
                :description="(slideOverStore?.description) ? $t(slideOverStore.description) : ''"
                :model-value="slideOverStore.isSlideOverOpen"
                @update:modelValue="slideOverStore.setSlideOverOpen($event)">
                <Component :is="components[slideOverStore.component]" />
            </BaseSlideOver>
            <main class="py-10 max-w-7xl mx-auto">
                <div class="sm:px-6 lg:px-8">
                    <!-- Your content -->
                    <slot />
                </div>
            </main>
        </div>
        <RequestLog />
    </div>
</template>

<script setup>
import FundingProgramsSelection from "@/components/global/FundingPrograms/FundingProgramsSelection";
import HeaderFrontend from "@/components/layouts/HeaderFrontend";
import ImpersonateBanner from "@/components/layouts/ImpersonateBanner";
import {useWebsocketStore} from "@/store/websockets";
import {useSlideOverStore} from "@/store/slideOver";
import {useStatic} from "@/store/static";

const slideOverStore = useSlideOverStore()
const staticStore = useStatic()

const components = {
    FundingProgramsSelection,
}

onMounted(() => {
    useWebsocketStore().init()
    staticStore.getStaticData()
})

useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})

// Comment out to deactivate the help pages
useHelpPage().listenForHelp()
</script>
